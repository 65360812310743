(function() {
  const items = document.querySelectorAll('.mobile-control__item-text');

  const onItemsClick = function(e) {
    //e.preventDefault();
  }

  // const onItemOver = function(e) {
  //   const target = e.currentTarget;
  //   target.classList.add('hovered');
  // }

  // const onItemLeave = function(e) {
  //   const target = e.currentTarget;
  //   target.classList.remove('hovered');
  // }

  // items.forEach(function(item) {
  //   item.addEventListener('click', onItemsClick);
  //   item.addEventListener('mouseenter', onItemOver);
  //   item.addEventListener('mouseleave', onItemLeave);
  // });

  // $('mobile-control__item-text').click(function() {
  //   $('.mobile-control__item-text').removeClass(ho)
  // });
})();
